<template>
  <div class="container">
    <Navbar pageName="이용약관" />
    <div class="legalContent">
      <h3>이용약관</h3>

      <b> 제1장 총 칙 </b>
      <b> 제1조 (목적)</b>
      <ul>
        <li>
          본 약관은 그린데이터랩 주식회사 (이하"그린데이터랩"이라 합니다)에서 운영하는 모바일 엡에서 제공하는 서비스를 이용함에 있어 “그린데이터랩”과 이용자의 권리ㆍ 의무 및 책임사항과 기타 필요한
          사항을 규정함을 목적으로 합니다. 그린데이터랩에서 운영하고 있는 모바일앱은 다음과 같습니다.
        </li>
      </ul>
      <b>제2조 (용어의 정의)</b>
      <ul>
        <li>①본 약관에서 사용하는 용어의 정의는 다음과 같습니다. 본 약관에서 정의하지 않은 것은 관계 법령 및 서비스별 안내에서 정하는 바에 따르며, 그 외에는 일반 관례에 따릅니다.</li>
        회원 : 서비스를 제공받기 위해 그린데이터랩이 인정 하는 절차를 통해 회원가입을 완료한 경우<br />
        비밀번호(Password) : 회원의 권익 보호를 위하여 회원이 선정한 숫자의문자의 조합<br />
        해지 : 그린데이터랩 또는 회원이 서비스 개통 후에 그 이용계약을 해약하는 것
        <br />
      </ul>
      <b>제3조 (약관의 명시와 설명 및 개정)</b>
      <ul>
        <li>①그린데이터랩은 본 약관의 내용과 상호, 영업소 소재지, 사업자등록번호, 연락처(전화) 등을 이용자가 알 수 있도록 게시합니다.</li>
        <li>
          ②본 약관을 공시하고 회원의 동의와 그린데이터랩의 승낙으로 효력을 발생하며, 약관의 규제 등에 관한 법률, 전자거래기본법, 전자서명법, 정보통신망이용촉진 등에 관한 법률, 방문판매 등에 관한 법률,
          소비자보호법 등 관련법을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
        </li>
        <li>
          ③그린데이터랩은 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 그린데이터랩의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게
          불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 그린데이터랩은 개정전 내용과 개정후 내용을 명확하게 비교하여 이용자가 알기 쉽도록
          표시합니다.
        </li>
        <li>
          ④그린데이터랩은 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미
          계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 그린데이터랩에 송신하여 그린데이터랩의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.
        </li>
        <li>
          ⑤회원은 변경된 약관 내용에 동의하지 않으면 서비스 이용을 중단하고 회원으로부터 탈퇴 할 수 있습니다. 단, 개정약관의 적용일 이후 상당 기간(100일 이상)동안 계속적으로 서비스를 이용하는 경우
          해당 회원은 약관의 변경에 동의한 것으로 간주될 수 있습니다.
        </li>
      </ul>

      <b>제4조 (규칙외 준칙)</b>
      <ul>
        <li>
          그린데이터랩은 필요한 경우 서비스 내의 개별항목에 대하여 개별약관 또는 운영원칙(이하 '서비스별 안내'라 합니다)를 정할 수 있으며, 본 약관과 서비스별 안내의 내용이 상충되는 경우에는 본 서비스
          약관의 내용을 우선하여 적용합니다.
        </li>
      </ul>
      <br />
      <b> 제2장 이용계약 체결</b>

      <b>제5조 (서비스의 제공 및 변경)</b>
      <ul>
        <li>①그린데이터랩은 다음과 같은 업무를 수행합니다.</li>
        <li>다양한 정보의 제공</li>
        <li>이용자에게 유용한 부가 서비스 및 그린데이터랩이 정하는 업무</li>
        <li>모바일 앱 제작 및 시스템 운영. 관리</li>
        <li>②그린데이터랩은 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 변경된 서비스 내용 및 제공일자를 명시하여 현재의 서비스 내용을 게시한 곳에 즉시 공지합니다.</li>
        <li>
          ③그린데이터랩이 제공하는 서비스의 기술적 사양 및 변경의 시유로 인하여 이용자가 입은 손해가 발생할시 배상을 합니다. 단, 그린데이터랩의 고의 또는 과실이 없는 경우에는 책임을 부담하지 않습니다.
        </li>
      </ul>
      <b>제6조(서비스의 중단)</b>
      <ul>
        <li>①그린데이터랩은 컴퓨터 등 정보통신설비의 보수점검·교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.</li>
        <li>
          ②제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, 그린데이터랩의 고의 또는 과실이 없음을 입증하는 경우에는 그러하지
          아니합니다.③ 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 그린데이터랩은 제 10조[회원에 대한 통지]에 정한 방법으로 이용자에게 통지합니다.
        </li>
      </ul>
      <b>제7조 (서비스의 성립 및 단위)</b>
      <ul>
        <li>
          이용 서비스는 약관 내용에 동의한 회원에 대해 그린데이터랩의 이용승낙에 의하여 성립됩니다. 이용서비스는 회원 아이디(ID) 단위로 진행되며, 회원 아이디(ID)는 1인에게 하나만 발급합니다. 다만,
          회원의 신청에 의하여 추가되는 부가서비스, 서비스의 대량 이용 또는 서비스 컴퓨터와 타 컴퓨터간의 연계서비스 등 서비스이용이 특별한 경우에는 별도의 서비스를 제공합니다.
        </li>
      </ul>
      <b>제8조 (회원가입)</b>
      <ul>
        <li>① 이용자는 회원가입이 무료이며 이용자는 그린데이터랩이 정한 가입 양식에 따라 회원정보를 기입한 후 본 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.</li>
        <li>
          ② 그린데이터랩은 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다. 가입신청자가 본 약관 제9조[회원 탈퇴 및 자격 상실 등] 제3항에 의하여
          이전에 회원자격을 상실한 적이 있는 경우, 다만 제9조[회원 탈퇴 및 자격 상실 등] 제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 그린데이터랩의 회원 재가입 승낙을 얻은 경우 등록 내용에 허위,
          기재누락, 오기가 있는 경우 등 그린데이터랩의 기술상 현저히 지장이 있다고 판단되는 경우
        </li>
        <li>③ 회원가입 성립 시기는 그린데이터랩의 승낙이 회원에게 도달한 시점으로 합니다.</li>
        <li>④ 그린데이터랩의 회원은 회원으로 가입하는 동시에 자격을 얻게 되며, 모바일앱에서 제공하는 다양한 서비스를 이용할 수 있습니다.</li>
        <li>⑤ 회원은 등록한 회원정보에 변경이 있는 경우, 즉시 그린데이터랩에서 정하는 방법에 따라 그 변경사항을 그린데이터랩에 통지하거나 수정하여야 합니다.</li>
      </ul>
      <b>제9조(회원 탈퇴 및 자격 상실 등)</b>
      <ul>
        <li>① 회원은 그린데이터랩이 운영하는 사이트에서 언제든지 탈퇴를 요청할 수 있으며 그린데이터랩은 즉시 회원탈퇴를 처리합니다.</li>
        <li>
          ② 회원이 다음 각 호의 사유에 해당하는 경우, 타인의 ID와 비밀번호 또는 그 개인정보를 도용한 경우 다른 사람의 그린데이터랩 이용을 방해하거나 그 정보를 도용, 타인의 명예를 손상시키거나 불이익을
          주는 행위 등 질서를 위협하는 경우 그린데이터랩 화면에 음란물을 게재하거나 음란사이트를 링크 시키는 경우 그린데이터랩을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는
          경우
        </li>
        <li>③ 그린데이터랩이 회원 자격을 제한·정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 그린데이터랩은 회원자격을 상실시킬 수 있습니다.</li>
        <li>④ 그린데이터랩이 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.</li>
      </ul>
      <b>제10조 (회원에 대한 통지)</b>
      <ul>
        <li>①그린데이터랩이 회원에 대한 통지를 하는 경우, 회원이 그린데이터랩과 미리 약정하여 문자와 전화 등의 개별통지로 할 수 있습니다.</li>
        <li>
          ②그린데이터랩은 불특정다수 회원에 대한 통지의 경우 1주일이상 그린데이터랩 게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에
          대하여는 개별통지를 합니다.
        </li>
      </ul>
      <b>제11조 (회원 서비스 이용 사항의 변경)</b>
      <ul>
        <li>회원은 서비스 이용 신청시 기재한 회원 정보가 변경되었을 경우에는 앱으로 수정을 하여야 하며, 변경하지 않은 정보로 인하여 발생되는 문제의 책임은 회원에게 있습니다.</li>
      </ul>
      <b>제12장 서비스 제공자의 의무</b>
      <ul>
        <li>①그린데이터랩은 법령과 본 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 본 약관이 정하는 바에 따라 지속적, 안정적으로 서비스를 제공하기 위해 최선을 다합니다.</li>
        <li>②그린데이터랩은 이용자가 안전하게 모바일 서비스를 이용할 수 있도록 이용자의 개인정보보호를 위한 보안 시스템을 갖추어야 합니다.</li>
        <li>③그린데이터랩은 계속적이고 안정적인 서비스의 제공을 위하여 설비에 장애가 생기거나 멸실된 때에는 부득이한 사유가 없는 한 지체 없이 이를 수리 또는 복구합니다.</li>
        <li>
          ④그린데이터랩은 회원으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 적절한 절차를 거쳐 즉시 처리하여야 합니다. 다만, 즉시 처리가 곤란한 경우는 회원에게 그 사유와
          처리일정을 통보하여야 합니다.
        </li>
      </ul>
      <b>제13조 (개인정보보호)</b>
      <ul>
        <li>
          그린데이터랩은 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련 법령 및 그린데이터랩의 개인정보취급방침이 적용됩니다. 또한,
          회원은 비밀번호 등이 타인에게 노출되지 않도록 철저히 관리해야 하며 그린데이터랩은 회원의 귀책사유로 인해 노출된 정보에 대해서 책임을 지지 않습니다. 그린데이터랩은 이용자의 회원 가입 시
          아래의 정보를 수집합니다.
        </li>

        <li>성명,전화번호,생년월일</li>

        <li>①그린데이터랩은 이용자의 개인 식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다.</li>
        <li>②제공된 개인정보는 당해 이용자의 동의 없이 목적 외의 이용이나 제 3자에게 제공할 수 없으며, 이에 대한 모든 책임은 그린데이터랩이 집니다.</li>

        <li>
          ③그린데이터랩이 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에 개인정보 관리 책임자의 신원(소속 및 전화번호 기타 연락처), 정보의 수립목적 및 이용목적, 제3자에 대한 정보제공 관련
          사항(제공 받는 자, 제공목적 및 제공할 정보의 내용) 정보통신망이용촉진 및 정보보호 등에 관한 법률 제 22조 제 2항에서 규정한 사항을 미리 명시하거나 고지해야 하며, 이용자는 언제든지 이 동의를
          철회할 수 있습니다.
        </li>
        <li>
          ④이용자는 언제든지 그린데이터랩이 가지고 있는 자신의 개인정보에 대해 열람 및 오류 정정을 요구할 수 있으며 그린데이터랩은 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의
          정정을 요구한 경우에는 그린데이터랩은 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
        </li>

        <li>⑤그린데이터랩 또는 그로부터 개인정보를 제공받은 제 3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.</li>
      </ul>
      <b>제14조 (개인정보의 위탁)</b>
      <ul>
        <li>①이용자의 동의 없이 해당 개인정보의 처리를 타인에게 위탁하지 않습니다.</li>

        <li>
          ②그린데이터랩은 위탁계약 체결 시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적·관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한
          사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
        </li>
      </ul>
      <b>제15조 (회원의 의무)</b>
      <ul>
        <li>①회원은 본 약관에서 규정하는 사항과 서비스 이용안내 및 주의사항을 준수하여야 합니다.</li>
        <li>②회원은 회원 가입 신청 양식에서 요구되는 등록정보를 진실하고, 정확하며, 현재의 사실과 일치하는 완전한 정보가 되도록 유지하고 이를 갱신해야 할 의무가 있습니다.</li>
        <li>③제13조를 제외한 ID와 비밀번호에 관한 관리 책임은 회원에게 있습니다.</li>
        <li>④회원이 자신의 ID 및 비밀번호를 도난당하거나 제 3자가 사용하고 있음을 인지한 경우에는 바로 그린데이터랩에 통보하고 그린데이터랩의 안내가 있는 경우에는 그에 따라야 합니다.</li>
        <li>
          ⑤회원은 사전 승낙 없이 상업적인 목적을 위하여 서비스를 이용하여 영업활동을 할 수 없으며, 그 영업활동의 결과에 대해 그린데이터랩은 책임을 지지 않습니다. 또한 회원은 이와 같은 영업활동으로
          그린데이터랩이 손해를 입은 경우, 회원은 그린데이터랩에 대해 손해배상의무를 지며, 그린데이터랩은 해당 회원에 대해 서비스 이용제한 및 적법한 절차를 거쳐 손해배상 등을 청구할 수 있습니다.
        </li>
        <li>⑥회원의 행동규범</li>
        <li>회원이 본 서비스를 통해 게재한 게시물에 대해서는 회원이 전적으로 책임을 집니다.</li>
        <li>회원은 불법적이거나 타인에게 해를 입히거나, 타인의 명예를 훼손하는 행위, 그린데이터랩의 업무에 방해가 되는 행위, 그린데이터랩의 명예를 손상시키는 행위를 하지 않습니다.</li>
        <li>회원은 타인의 개인정보를 수집 또는 저장하여 유용하지 않습니다.</li>
      </ul>
      <b>제16조 (서비스 이용제한)</b>
      <ul>
        <li>
          ①회원이 범죄적 행위와 관련되는 경우, 공공질서 및 미풍양속에 반하는 경우, 타인의 며예를 손상시키는 경우 그린데이터랩은 자체 심의를 거쳐 자료의 게재를 제한하거나 삭제하는 이용을 제한 할 수
          있습니다.
        </li>

        <li>그린데이터랩의 운영진, 직원 또는 관계자를 사칭하거나 고의로 서비스를 방해하는 등 정상적인 서비스 운영에 방해가 될 경우</li>
        <li>그린데이터랩은 이외에도 별도의 게시물 관리정책을 통해 게시물을 관리/운영할 수 있습니다.</li>
        <li>②회원은 그린데이터랩이 서비스안내에 게시 또는 별도로 공시한 이용제한 사항을 준수하여야 합니다.</li>
        <li>③그린데이터랩은 국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등 불가피한 사정으로 서비스 이용에 지장이 있는 때에는 서비스의 전부 또는 일부를 제한하거나 정지할 수 있습니다</li>
      </ul>

      <b>제17조 (불건전이용 신고처리)</b>
      <ul>
        <li>
          ①그린데이터랩은 각 사이트를 유익하고 건전하게 이용하도록 불건전 이용에 대한 신고를 받아 자체 심의를 거쳐 제16조[서비스 이용제한] 제1항의 각 호에 해당하는 경우 자료의 게재를 제한하거나
          삭제하는 등 서비스 이용을 제한하거나 회원 아이디(ID)를 해지할 수 있습니다.
        </li>
        <li>②신고인은 신고인의 성명, 연락처를 밝히고 불건전 내용, 검색일시, 불건전 내용을 발견한 영역 등을 증명할 수 있는 형태로 신고할 수 있습니다.</li>
        <li>③그린데이터랩은 신고내용이 불확실하거나 별도의 자료가 필요한 경우에는 신고인에게 자료의 보완을 요구할 수 있고 신고내용이 다음 각 호에 해당하는 경우에는 이를 심의하지 않습니다.</li>
        <li>심의 대상에 해당하지 아니하는 경우</li>
        <li>신고사항이 사실에 명백히 반하는 경우</li>
        <li>신고사항이 경미함에도 악의에 의해 과장되었거나, 지나치게 편향된 경우</li>
        <li>신고사항이 모호할 경우5. 신고인의 인적사항이 분명하지 아니한 경우. 다만, 사안에 따라서는 신고인이 성명, 연락처를 밝히지 아니하더라도 이를 신고된 것으로 인정한다.</li>
      </ul>
      <b>제18조 (면책조항)</b>
      <ul>
        <li>①그린데이터랩은 서비스 이용 중 그린데이터랩의 책임 사유 없이 발생한 어떠한 손해에 관하여도 책임지지 않습니다.</li>
        <li>②그린데이터랩은 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.</li>
        <li>③그린데이터랩은 회원의 귀책사유로 인하여 서비스 이용의 장애가 발생한 경우에는 책임이 면제됩니다.</li>
        <li>④그린데이터랩은 회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 그 내용에 관하여 책임이 면제 됩니다.</li>
        <li>
          ⑤본 약관은 그린데이터랩과 회원 간에 성립되는 서비스이용의 기본약정입니다. 그린데이터랩은 필요한 경우 특정 서비스에 관하여 적용될 사항(이하 "개별약관"이라고 합니다)을 정하여 미리 공지할 수
          있습니다. 회원이 이러한 개별약관에 동의하고 특정 서비스를 이용하는 경우에는 개별약관이 우선적으로 적용되고, 본 약관은 보충적인 효력을 갖습니다.
        </li>
      </ul>
      <b>제19조 (저작권의 귀속 및 이용제한)</b>
      <ul>
        <li>①그린데이터랩이 작성한 저작물에 대한 저작권 기타 지적재산권은 그린데이터랩에 귀속합니다.</li>
        <li>
          ②이용자는 그린데이터랩을 이용함으로써 얻은 정보 중 그린데이터랩에게 지적재산권이 귀속된 정보를 그린데이터랩의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로
          이용하거나 제3자에게 이용하게 하여서는 안 됩니다.
        </li>
        <li>③그린데이터랩은 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.</li>
        <li>④회원이 창작하여 그린데이터랩에 게재 또는 등록한 게시물에 대한 저작권은 회원 본인에게 있으며 해당 게시물이 타인의 지적 재산권을 침해하여 발생되는 모든 책임은 회원 본인에게 해당됩니다.</li>
        <li>
          그린데이터랩이 제공하는 관련 서비스 내에서 회원들의 게시물에 대한 복제, 전시, 배포, 편집 저작물 작성 및 회원의 게시물을 상업적으로 이용하고자 할 경우 회원의 사전동의를 얻어야 한다.
          그린데이터랩이 회원의 게시물을 상업적으로 이용할 경우 별도의 보상제도를 운영할 수 있다.
        </li>
        <li>
          ⑥회원의 게시물에 대해 제 3자로부터 저작권 및 기타 권리의 침해로 이의가 제기된 경우 그린데이터랩은 해당 게시물을 임의로 삭제할 수 있으며 해당 게시물에 대한 법적문제가 종결된 후 이를 근거로
          그린데이터랩에 신청이 있는 경우에만 상기 임시 삭제된 게시물을 다시 게재할 수 있습니다.
        </li>
        <li>
          ⑦회원이 이용계약 해지를 한 경우 본인 계정에 기록된 게시물(ex. 영농일지 등) 일체는 삭제됩니다. 단, 타인에 의해 보관, 담기 등으로 재게시 되거나 복제된 게시물과 타인의 게시물과 결합되어
          제공되는 게시물, 공용 게시판에 등록된 게시물 등은 그러하지 않습니다.
        </li>
      </ul>
      <b>제20조 (분쟁해결)</b>
      <ul>
        <li>①그린데이터랩은 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치·운영합니다.</li>
        <li>②그린데이터랩은 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.</li>
        <li>③그린데이터랩과 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.</li>
      </ul>
      <b>제21조 (재판권 및 준거법)</b>
      <ul>
        <li>①그린데이터랩과 이용자간에 발생한 서비스 이용에 관한 분쟁에 대하여는 대한민국 법을 적용하며, 본 분쟁으로 인한 소는 대한민국의 법원에 제기합니다.</li>
      </ul>
      <b>부칙</b>
      1. 본 약관은 2022년 11월 7일부터 유효합니다.
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
export default {
  components: { Navbar },

  data() {
    return {};
  },
  created() {
    this.$store.dispatch("SET_MENU_VISIBLE", false);
    this.$store.dispatch("SET_NAVBAR_VISIBLE", false);
  },
  methods: {},
};
</script>
