<template>
  <div class="container join">
    <div class="baseBox">
      <div class="joinForm">
        <p>
          이름
          <span>*</span>
        </p>
        <input type="text" v-model="username" />
        <div>
          <p>
            휴대전화
            <span>*</span>
          </p>
          <div class="flexB">
            <input type="tel" v-model="mobileNo" @keyup="removeChar" />
            <div class="cert">
              <button class="line" @click="requestCertNumber">인증번호 받기</button>
            </div>
          </div>
        </div>
        <div v-show="isRequestCertNum">
          <p>
            인증번호
            <span>*</span>
          </p>
          <div class="flexB">
            <input class="tel" type="number" v-model="certNumber" @keyup="removeChar" />
            <div class="cert">
              <button class="line" @click="verifyNumber">인증번호 확인</button>
            </div>
          </div>
        </div>
        <p>
          비밀번호
          <span>*</span>
        </p>
        <div class="password">
          <input type="password" v-model="password" />
        </div>
        <p>
          비밀번호확인
          <span>*</span>
        </p>
        <div class="password">
          <input type="password" v-model="passwordConfirm" />
        </div>

        <div class="agree">
          <div class="flexB">
            <el-checkbox type="checkbox" v-model="checkAll" @change="handleCheckAll"><span class="bold">전체동의</span></el-checkbox>
          </div>
          <div class="flexB">
            <el-checkbox v-model="userTerms" @change="handleTerms" ref="agreeCheck">(필수) 회원 이용약관</el-checkbox>
            <button class="line" @click="handleTermsView('userTerms')">내용확인</button>
          </div>
          <div class="flexB">
            <el-checkbox v-model="privacyPolicy" @change="handleTerms" ref="agreeCheck"> (필수) 개인정보 처리방침</el-checkbox>
            <button class="line" @click="handleTermsView('TermsPrivacy')">내용확인</button>
          </div>
        </div>
        <button class="point full" @click="submit">회원가입</button>
      </div>
      <el-drawer :visible.sync="drawer" :direction="direction" :before-close="handleClose" size="100%">
        <component v-bind:is="currentComponent"></component>
      </el-drawer>
    </div>
  </div>
</template>
<script>
import { format } from "@/mixins/format";
import moment from "moment";
import { verifyMobile, createCertNumber, createUser } from "@/api/index";
import UserTerms from "@/views/terms/UserTerms.vue";
import TermsPrivacy from "@/views/terms/TermsPrivacy.vue";
import MarketingAgreement from "@/views/terms/MarketingAgreement.vue";
export default {
  mixins: [format],
  components: { UserTerms, TermsPrivacy, MarketingAgreement },

  data() {
    return {
      moment: moment,
      next: false,

      userType: "",
      mobileNo: "",
      username: "",
      password: "",
      passwordConfirm: "",
      userTerms: false,
      privacyPolicy: false,
      marketingAgreement: false,
      checkAll: false,

      drawer: false,
      direction: "btt",
      currentComponent: null,
      certNumber: "",
      isVerify: false,
      isRequestCertNum: false,
    };
  },
  created() {
    this.$store.dispatch("SET_PAGE_NAME", "회원가입");
    this.$store.dispatch("SET_MENU_VISIBLE", false);
    this.$store.dispatch("SET_NAVBAR_VISIBLE", true);
  },
  methods: {
    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },
    handleTermsView(component) {
      this.drawer = true;
      this.currentComponent = component;
    },
    handleTerms(e) {
      if (e == true && this.userTerms == true && this.privacyPolicy == true) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
    },
    handleCheckAll(e) {
      if (e == true) {
        this.userTerms = true;
        this.privacyPolicy = true;
        this.checkAll = true;
      } else {
        this.userTerms = false;
        this.privacyPolicy = false;
        this.checkAll = false;
      }
    },
    handleClose() {
      this.$store.dispatch("SET_NAVBAR_VISIBLE", true);

      this.drawer = false;
      var container = this.$el.querySelector(".el-drawer__body");
      container.scrollTop = 0;
    },

    removeChar(event) {
      if (event.keyCode == 8 || event.keyCode == 46 || event.keyCode == 37 || event.keyCode == 39) return;
      else event.target.value = event.target.value.replace(/[^0-9]/g, "");
    },
    requestCertNumber() {
      if (this.mobileNo == "") {
        this.openAlert("휴대폰번호를 입력해 주세요.");
        return;
      } else if (this.mobileNumberCheck(this.mobileNo) == false) {
        this.openAlert("휴대폰번호 형식이 맞지 않습니다.");
        return;
      }
      let data = {
        mobileNo: this.mobileNo,
      };
      this.isRequestCertNum = true;
      createCertNumber(data).then((res) => {
        if (res.data.status == 200) {
          this.openAlert("모바일을 통해서 인증번호를 확인 후 인증번호를 입력해주세요.");
        } else {
          this.openAlert(res.data.message);
        }
      });
    },
    verifyNumber() {
      if (this.certNumber == "") {
        this.openAlert("인증 번호를 입력해 주세요.");
        return;
      }
      let data = {
        mobileNo: this.mobileNo,
        certNumber: this.certNumber,
      };
      verifyMobile(data).then((res) => {
        if (res.data.status == 200) {
          this.isVerify = true;
          this.openAlert("정상적으로 인증이 완료 되었습니다.");
        } else {
          this.isVerify = false;
          this.openAlert(res.data.message);
        }
      });
    },
    submit() {
      if (this.username == "") {
        this.openAlert("이름을 입력해 주세요.");
        return;
      } else if (this.mobileNo == "") {
        this.openAlert("휴대폰번호를 입력해 주세요.");
        return;
      } else if (this.isVerify == false) {
        this.openAlert("휴대폰번호 인증을 진행해 주세요.");
        return;
      } else if (this.password == "") {
        this.openAlert("비밀번호를 입력해 주세요.");
        return;
      } else if (this.password != this.passwordConfirm) {
        this.openAlert("비밀번호를 확인해주세요.");
        return;
      } else if (this.userTerms == false || this.privacyPolicy == false) {
        this.openAlert("이용약관 및 개인정보 처리방침는 필수 입니다.");
        return;
      }

      let data = {
        username: this.username,
        mobileNo: this.mobileNo,
        password: this.password,
        userType: "USER",
        userTerms: this.userTerms,
        privacyPolicy: this.privacyPolicy,
      };
      createUser(data).then((res) => {
        if (res.data.status == 200) {
          this.$router.replace("/joinDone");
        } else {
          this.openAlert(res.data.message);
        }
      });
    },
  },
};
</script>
